<template>
    <sui-container id="landing">
        <sui-grid vertical-align="middle" columns="two" centered>
            <sui-grid-row>
                <sui-grid-column mobile="sixteen" tablet="eight" computer="eight">
                    <img alt="tsaro logo" src="../assets/tsaro-logo.png">
                </sui-grid-column>
                <sui-grid-column mobile="sixteen" tablet="eight" computer="eight" id="choose">
                    <p>Please choose next step</p>
                    <sui-button size="medium" color="blue" @click="userLogin">Login</sui-button>
                    <sui-button size="medium" color="blue" @click="userRegistry">Register</sui-button>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-container>
</template>



<script>
    import("../../src/css/landing.css")

    export default {
      name: 'Home',
      title: "Tsaro Video Solutions",
      components: {
      },
      methods: {
          userLogin: function(){
              this.$router.push('login')
          },
          userRegistry: function(){
              this.$router.push('register')
          }
      }
    }
</script>
