import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Empty from "@/views/dashboard/Empty";
//import TestOutgoing from "@/views/TestOutgoing";
import TestIncoming from "@/views/TestIncoming";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: 'Tsaro Camera Solutions'
        },
        component: Home
    },
    /*
    {
        path: '/test-outgoing',
        name: 'Outgoing Test Call',
        meta: {
            title: 'Test Outgoing Phone Call'
        },
        component: TestOutgoing
    },
     */
    {
        path: '/test-incoming',
        name: 'Incoming Test Call',
        meta: {
            title: 'Test Incoming Phone Call'
        },
        component: TestIncoming
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: 'User Login - Tsaro Camera Solutions'
        },
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/register/:token?',
        name: 'Register',
        meta: {
            title: 'User Register - Tsaro Camera Solutions'
        },
        component: () => import('@/views/Register.vue')
    },
    {
        path: '/v/:sessionKey',
        name: 'Tsaro Video',
        meta: {
            title: 'Tsaro Video - Tsaro Camera Solutions'
        },
        component: () => import('@/views/MagicSIP.vue')
    },
    {
        path: '/dashboard',
        component: () => import('@/views/Dashboard.vue'),
        children: [
            {
                path: 'camera',
                component: Empty,
                children: [
                    {
                        path: 'add',
                        name: 'CameraAdd',
                        meta: {
                            title: 'Add Camera - Tsaro Camera Solutions'
                        },
                        component: () => import("@/views/dashboard/camera/CameraAdd"),
                    },
                    {
                        path: 'view/:cameraId',
                        name: 'CameraView',
                        meta: {
                            title: 'View Camera - Tsaro Camera Solutions'
                        },
                        component: () => import("@/views/dashboard/camera/CameraView"),
                    },
                    {
                        path: 'edit/:cameraId',
                        name: 'CameraEdit',
                        meta: {
                            title: 'Edit Camera - Tsaro Camera Solutions'
                        },
                        component: () => import("@/views/dashboard/camera/CameraEdit"),
                    },
                    {
                        path: '',
                        name: 'Camera',
                        meta: {
                            title: 'Camera - Tsaro Camera Solutions'
                        },
                        component: () => import("@/views/dashboard/Camera"),
                    }
                ]
            },
            {
                path: 'contact',
                component: Empty,
                children: [
                    {
                        path: 'add',
                        name: 'ContactAdd',
                        component: () => import("@/views/dashboard/contact/ContactAdd"),
                    },
                    {
                        path: 'edit/:id',
                        name: 'ContactEdit',
                        meta: {
                            title: 'Edit User - Tsaro Camera Solutions'
                        },
                        component: () => import("@/views/dashboard/contact/ContactEdit"),
                    },
                    {
                        path: '',
                        name: 'Contact',
                        component: () => import("@/views/dashboard/Contact"),
                    }
                ]
            },

            {
                path: 'access',
                component: Empty,
                children: [
                    {
                        path: 'add',
                        name: 'AccessAdd',
                        meta: {
                            title: 'Add User - Tsaro Camera Solutions'
                        },
                        component: () => import("@/views/dashboard/access/AccessAdd"),
                    },
                    {
                        path: 'edit/:uid',
                        name: 'Access.Edit',
                        meta: {
                            title: 'Add User - Tsaro Camera Solutions'
                        },
                        component: () => import("@/views/dashboard/access/AccessEdit"),
                    },
                    {
                        path: '',
                        name: 'Access',
                        meta: {
                            title: 'Access Management - Tsaro Camera Solutions'
                        },
                        component: () => import("@/views/dashboard/Access"),
                    }
                ]
            },
            {
                path: 'location',
                component: Empty,
                children: [
                    {
                        path: 'add',
                        name: 'LocationAdd',
                        component: () => import("@/views/dashboard/location/LocationAdd")
                    },
                    {
                        path: 'edit/:id',
                        name: 'LocationEdit',
                        meta: {title: 'Edit Location - Tsaro Camera Solutions'},
                        component: () => import("@/views/dashboard/location/LocationEdit"),
                    },
                    {
                        path: '',
                        name: 'Location',
                        component: () => import("@/views/dashboard/Location")
                    }
                ]
            },
            {
                path: 'home',
                name: 'Dashboard',
                component: () => import("@/views/dashboard/DashboardHome")
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router
