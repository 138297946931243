<template>
  <div id="outgoingSession">
    <sui-grid>
      <sui-grid-row :columns="2"  id="video-header">
        <sui-grid-column>
          <h3 is="sui-header" dividing>Local</h3>
        </sui-grid-column>
        <sui-grid-column>
          <h3 is="sui-header" dividing>Remote</h3>
        </sui-grid-column>
      </sui-grid-row>
      <sui-grid-row :columns="2">
        <sui-grid-column>
          <video id="local-video" class="video-frame" muted autoplay controls></video>
        </sui-grid-column>
        <sui-grid-column>
          <video id="remote-video" class="video-frame" autoplay controls></video>
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>
  </div>
</template>

<script>
export default {
  name: "OutgoingSession",
  props: {
    session: Object,
    status: String,
  },
  computed: {},
  watch: {
    status: function () {
      console.log("[session] status");
    },
    session: function () {
      console.log("[session] session");
      this.updateVideoSession();
    }
  },
  mounted() {
    console.log("[session] 1. mounted");
  },
  created() {
    console.log("[session] 2. created");
  },
  data: function () {
    return {
      localHasVideo: false,
      remoteHasVideo: false,
      localHold: false,
      remoteHold: false,
      canHold: false,
      ringing: false,
      mounted: false,
      localClonedStream: null
    }
  },
  methods: {
    updateVideoSession: function () {
      this.mounted = true;

      const remoteVideo = document.getElementById("remote-video");
      const localVideo = document.getElementById("local-video");
      const session = this.session;

      if (session === null) {

        remoteVideo.srcObject = null;
        localVideo.srcObject = null;

        return;
      }

      const peerConnection = session.connection;
      const localStream = peerConnection.getLocalStreams()[0];
      const remoteStream = peerConnection.getRemoteStreams()[0];

      // Handle local stream
      if (localStream) {
        // Clone local stream
        this.localClonedStream = localStream.clone();

        // Display local stream
        localVideo.srcObject = this.localClonedStream;

        setTimeout(() => {
          if (localStream.getVideoTracks()[0])
            this.localHasVideo = true;
        }, 1000);
      }

      // If incoming all we already have the remote stream
      if (remoteStream) {
        console.log('already have a remote stream');

        this.handleRemoteStream(remoteStream);
      }

      if (session.isEstablished()) {
        setTimeout(() => {
          if (!this.mounted)
            return;

          this.canHold = true;
        });
      }

      session.on('progress', (data) => {
        if (!this.mounted)
          return;

        console.log('session "progress" event [data:%o]', data);

        if (session.direction === 'outgoing')
          this.ringing = true;
      });

      session.on('accepted', (data) => {
        if (!this.mounted)
          return;

        console.log('session "accepted" event [data:%o]', data);

        if (session.direction === 'outgoing') {
          console.log({
            level: 'success',
            title: 'Call answered'
          });
        }

        this.canHold = true;
        this.ringing = false;
      });

      session.on('failed', (data) => {
        if (!this.mounted)
          return;

        console.log('session "failed" event [data:%o]', data);

        console.log({
          level: 'error',
          title: 'Call failed',
          message: `Cause: ${data.cause}`
        });

        if (session.direction === 'outgoing') {
          this.ringing = false;
        }
      });

      session.on('ended', (data) => {
        if (!this.mounted)
          return;

        console.log('session "ended" event [data:%o]', data);

        console.log({
          level: 'info',
          title: 'Call ended',
          message: `Cause: ${data.cause}`
        });

        if (session.direction === 'outgoing') {
          this.ringing = false;
        }

      });

      session.on('hold', (data) => {
        if (!this.mounted)
          return;

        const originator = data.originator;

        console.log('session "hold" event [originator:%s]', originator);

        switch (originator) {
          case 'local':
            this.localHold = true;
            break;
          case 'remote':
            this.remoteHold = true;
            break;
        }
      });

      session.on('unhold', (data) => {
        if (!this.mounted)
          return;

        const originator = data.originator;

        console.log('session "unhold" event [originator:%s]', originator);

        switch (originator) {
          case 'local':
            this.localHold = false;
            break;
          case 'remote':
            this.remoteHold = false;
            break;
        }
      });

      peerConnection.addEventListener('addstream', (event) => {
        console.log('peerConnection "addstream" event');

        if (!this.mounted) {
          console.log('handleRemoteStream() | component not mounted');

          return;
        }

        this.handleRemoteStream(event.stream);
      });
    },
    ////////////////////////////////////////////////////////////
    handleRemoteStream: function (stream) { // Done
      console.log('_handleRemoteStream() [stream:%o]', stream);

      const remoteVideo = document.getElementById("remote-video");

      console.log(remoteVideo);

      // Display remote stream
      remoteVideo.srcObject = stream;


      this.checkRemoteVideo(stream);

      stream.addEventListener('addtrack', (event) => {
        const track = event.track;

        if (remoteVideo.srcObject !== stream)
          return;

        console.log('remote stream "addtrack" event [track:%o]', track);

        // Refresh remote video
        remoteVideo.srcObject = stream;

        this.checkRemoteVideo(stream);

        track.addEventListener('ended', () => {
          console.log('remote track "ended" event [track:%o]', track);
        });
      });

      stream.addEventListener('removetrack', () => {
        if (remoteVideo.srcObject !== stream)
          return;

        console.log('remote stream "removetrack" event');

        // Refresh remote video
        remoteVideo.srcObject = stream;

        this.checkRemoteVideo(stream);
      });
    },
    ////////////////////////////////////////////////////////////
    checkRemoteVideo: function (stream) { //Done
      if (!this.mounted) {
        console.log('_checkRemoteVideo() | component not mounted');

        return;
      }

      const videoTrack = stream.getVideoTracks()[0];
      this.remoteHasVideo = Boolean(videoTrack);
    }
    // Add method here
  }
}
</script>

<style scoped>

.video-frame {
  width: 100%;
  margin-bottom: 10px;
  background: black;
}

#video-header{
  padding-bottom: 0;
}

</style>
