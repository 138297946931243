<template>
  <sui-container id="main dashboard">
    <sui-menu id="main-menu">
      <sui-menu-item header link>
        <img src="../../src/assets/tsaro-logo-header.png" id="logo"/>
      </sui-menu-item>
      <sui-menu-menu position="right">
      </sui-menu-menu>
    </sui-menu>
    <sui-grid columns="two" centered id="sidebar">
      <sui-grid-row>
        <sui-grid-column :width="4" :mobile="16" :tablet="4" :computer="4">
          <sui-menu vertical color="blue" inverted>
            <router-link to="/dashboard/home" is="sui-menu-item" active-class="active">
              <sui-icon name="home"></sui-icon>
              Home
            </router-link>
          </sui-menu>
          <sui-divider clearing hidden></sui-divider>
        </sui-grid-column>
        <sui-grid-column :width="12" :mobile="16" :tablet="12" :computer="12">
          <!-- ============ Main Content BEGIN ============ -->
          <OutgoingSession :session="session" :status="status"></OutgoingSession>
          <sui-form>
            <sui-form-field>
              <sui-input placeholder="password" id="password" v-model="settings.password"></sui-input>
            </sui-form-field>
            <sui-form-fields>
              <sui-form-field>
                <sui-button @click.prevent="connect" color="blue">Connect To SIP Service</sui-button>
              </sui-form-field>
              <sui-form-field>
                <sui-button @click.prevent="hangUp" color="red">Hang Up Call</sui-button>
              </sui-form-field>
            </sui-form-fields>

          </sui-form>
          <p></p>
          <p>{{ status }}</p>
          <!-- ============ Main Content END ============ -->
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>
  </sui-container>
</template>

<script>
import JsSIP from "jssip";
import OutgoingSession from "@/views/dashboard/video/OutgoingSession";

export default {
  name: "TestIncoming",
  components: {OutgoingSession},
  data: function () {
    return {
      sipEndpoint: "experiment-dashboard@tsaro-alpha.sip.signalwire.com",

      // 'connecting' / disconnected' / 'connected' / 'registered'
      status: 'disconnected',
      uri: '',

      settings: {
        display_name: "caller",
        //uri: "experiment-dashboard@tsaro-alpha.sip.signalwire.com",
        uri: "654321@tsaro-alpha.sip.signalwire.com",
        password: "5f61dae96430",
        socket: {
          uri: "wss://tsaro-alpha.sip.signalwire.com",
          via_transport: "tls"
        },
        registrar_server: "tsaro-alpha.sip.signalwire.com",
        contact_uri: null,
        authorization_user: null,
        instance_id: null,
        session_timers: true,
        use_preloaded_route: false,
        pcConfig: {
          rtcpMuxPolicy: "negotiate",
          iceServers: [
            {
              urls: ["stun:stun.l.google.com:19302"]
            }
          ]
        },
        callstats: {
          enabled: false,
          AppID: null,
          AppSecret: null
        }
      },

      session: null,
      incomingSession: null,

      mounted: false,
      ua: null,
      u: null,
    }
  },
  destroyed() {
    console.log("vue destroyed")
    this.mounted = false;
  },
  created: async function () {
    console.log("created");
  },
  methods: {
    connect: async function () {

      this.mounted = true;
      const logger = console;

      const settings = this.settings;
      const socket = new JsSIP.WebSocketInterface(settings.socket.uri);

      if (settings.socket['via_transport'] !== 'auto')
        socket['via_transport'] = settings.socket['via_transport'];


      try {
        this.ua = new JsSIP.UA(
            {
              uri: settings.uri,
              password: settings.password,
              'display_name': settings.display_name,
              sockets: [socket],
              'registrar_server': settings.registrar_server,
              'contact_uri': settings.contact_uri,
              'authorization_user': settings.authorization_user,
              'instance_id': settings.instance_id,
              'session_timers': settings.session_timers,
              'use_preloaded_route': settings.use_preloaded_route
            });

        // TODO: For testing.
        window.UA = this.ua;
        console.log("successfully created instance")
      } catch (error) {
        console.log({
          level: 'error',
          title: 'Wrong JsSIP.UA settings',
          message: error.message
        });

      }


      this.ua.on('connecting', () => {
        if (!this.mounted)
          return;

        logger.log('UA "connecting" event');

        this.uri = this.ua.configuration.uri.toString();
        this.status = 'connecting';

      });


      this.ua.on('connected', () => {
        if (!this.mounted)
          return;

        logger.log('UA "connected" event');

        this.status = 'connected';
      });

      this.ua.on('disconnected', () => {
        if (!this.mounted)
          return;

        logger.log('UA "disconnected" event');

        this.status = 'disconnected';
      });

      this.ua.on('registered', () => {
        if (!this.mounted)
          return;

        logger.log('UA "registered" event');

        this.status = 'registered';
      });

      this.ua.on('unregistered', () => {
        if (!this.mounted)
          return;

        logger.log('UA "unregistered" event');

        if (this.ua.isConnected()) {
          this.status = 'connected';
        } else {
          this.status = 'disconnected';
        }
      });

      this.ua.on('registrationFailed', (data) => {
        if (!this.mounted)
          return;

        logger.log('UA "registrationFailed" event');

        if (this.ua.isConnected()) {
          this.status = 'connected';
        } else {
          this.status = 'disconnected';
        }

        logger.log({
          level: 'error',
          title: 'Registration failed',
          message: data.cause
        });
      });


      // New RTC Connection
      this.ua.on('newRTCSession', (data) => {

        logger.log('UA "newRTCSession" event');

        if (!this.mounted)
          return;

        // TODO: For testing.
        window.SESSION = data.session;

        if (data.originator === 'local')
          return;

        logger.log('UA "newRTCSession" event');


        const session = data.session;

        // Avoid if busy or other incoming
        if (this.session || this.incomingSession) {
          logger.log('incoming call replied with 486 "Busy Here"');

          session.terminate(
              {
                'status_code': 486,
                'reason_phrase': 'Busy Here'
              });

          return;
        }

        //audioPlayer.play('ringing');
        //this.setState({ incomingSession: session });
        this.incomingSession = session;

        session.on('failed', () => {
          console.log("session failed");

          this.incomingSession = null;
          this.session = null;
        });

        session.on('ended', () => {
          console.log("session ended");

          this.incomingSession = null;
          this.session = null;
        });

        session.on('accepted', () => {
          console.log("session accepted");

          this.session = session;
          this.incomingSession = null;
        });


        this.answerPhone()

      });

      //Star UA
      this.ua.start();

    },
    answerPhone: async function () {
      console.log("Answer Phone")
      const session = this.incomingSession;

      session.answer({
        pcConfig: this.settings.pcConfig
      });

    },
    hangUp: async function () {
      console.log('hangUp');

      this.ua.stop();

    }
  }
}
</script>

<style scoped>
#logo {
  width: 3.5em;
}

#main-menu {
  margin-top: 20px;
}

#sidebar .menu {
  width: 100%;
}

.ui.menu .hide {
  display: none;
}

.ui.inverted.blue.menu, .ui.inverted.menu .blue.active.item {
  background-color: #3a98fe;
}

#user-role-text {
  color: lightgray;
  margin-left: 5px;
  font-size: 80%;
}


</style>
