import Vue from 'vue'
import App from './App.vue'
import router from './router'
import SuiVue from 'semantic-ui-vue'
import VueCookies from 'vue-cookies'

import 'semantic-ui-css/semantic.min.css'

Vue.config.productionTip = false
Vue.use(SuiVue)
Vue.use(VueCookies)

Vue.prototype.$config = {
  _userRole: "",
  _userId: -1,
  userRole: async function() {
    if (this._userRole !== "") {
      return this._userRole;
    }else{
      let p = new Promise((resolve) => {
          this.waitingBuffers.push(resolve);
      });
      await p;
      return this._userRole;
    }
  },
  userId: async function() {
    if (this._userId  === -1) {
      return this._userId;
    }else{
      let p = new Promise((resolve) => {
          this.waitingBuffers.push(resolve);
      });
      await p;
      return this._userId;
    }
  },
  waitingBuffers: []
}



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
